<template>
  <div>
  <v-list-item dense link>
    <v-list-item-avatar tile>
      <planete-icon width="24" height="24">{{ category.icon }}</planete-icon>
    </v-list-item-avatar>
    <v-list-item-content @click="showMenu = !showMenu" class="clickable">
      <v-list-item-title>
        {{ $t("notifications." + category.slug + ".subtitle") }}
        <v-icon>{{ showMenu ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ htmlDecode(notification.data.title) }}
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        {{ "Le " + notification.data.date + " à " + notification.data.hour }}
      </v-list-item-subtitle>

    </v-list-item-content>




  </v-list-item>
  <v-expand-transition>
    <v-card v-show="showMenu" class="mx-4 elevation-0">
      <v-card-text v-html="notification.data.text" class="mb-2">

      </v-card-text>
    </v-card>
  </v-expand-transition>
</div>
</template>

<script>
export default {
  name: "PoseSdp",
  props: {
    notification: Object,
    category: Object
  },
  data() {
    return {
      showMenu: false
    }
  }
}
</script>

<style scoped>
  .clickable {
    cursor: pointer;
  }
</style>